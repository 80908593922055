import React from "react";
import Heading from "../elements/Heading";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const BackNavbar = ({ periodicy, tasksCount, tasksDoneCount, loading }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center gap-10 p-4 shadow-2xl">
      {periodicy !== "Eventual" ? (
        <Heading>
          {loading ? (
            "Cargando..."
          ) : (
            <p
              className={
                tasksCount === tasksDoneCount
                  ? "text-green-500"
                  : "text-yellow-500"
              }
            >
              Completadas {tasksDoneCount} de {tasksCount}
            </p>
          )}
        </Heading>
      ) : null}
      <button onClick={() => navigate("/hola/tareasasignadas")} className="btn">
        <Heading>Atrás</Heading>
        <IoArrowBackSharp size={25} />
      </button>
    </div>
  );
};

export default BackNavbar;
