import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import NumericInput from "../elements/NumericInput";
import { db } from "../firebase/FirebaseConfig";

const CancelDoneTaskForm = ({
  cancelRequest,
  docID,
  listID,
  isSubmitting,
  setIsSubmitting,
  setSuccess,
}) => {
  const [pinNumber, setPinNumber] = useState("");
  const [error, setError] = useState(false);
  const [invalidPin, setInvaldiPin] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(false);
    setInvaldiPin(false);
    const queryColRef = collection(db, "usuarios");
    const q = query(queryColRef, where("PIN", "==", parseInt(pinNumber)));
    const docRef = doc(db, "listas", listID, "tareas", docID);
    try {
      const search = await getDocs(q);
      if (search.docs.length === 0) {
        setError(true);
        setInvaldiPin(true);
      } else {
        updateDoc(docRef, {
          done: false,
        });
        setSuccess(true);
      }
    } catch (error) {
      setError(true);
      console.log(error);
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col gap-2">
      <label htmlFor="instruction">Ingrese PIN del administrador</label>
      {error && (
        <p className="text-red-500">
          {invalidPin
            ? "Número de PIN Incorrecto"
            : "Ha ocurrido un error, intente mas tarde"}
        </p>
      )}
      <div className="select-none">
        <NumericInput
          value={pinNumber}
          onChange={setPinNumber}
          placeholder="PIN de cancelación"
        />
      </div>
      <div className="flex justify-center gap-8">
        <button
          type="button"
          className="btn btn-neutral"
          onClick={cancelRequest}
        >
          Vovler
        </button>
        <button
          type="submit"
          className="btn btn-error"
          disabled={pinNumber === "" || isSubmitting}
        >
          Cancelar Tarea
        </button>
      </div>
    </form>
  );
};

export default CancelDoneTaskForm;
