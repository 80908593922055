import React from "react";
import { NavLink } from "react-router-dom";
import { useEmployee } from "../../context/EmployeeContext";
import Heading from "../../elements/Heading";
import Subtext from "../../elements/Subtext";
import useGetListForEmployee from "../../hooks/useGetListForEmployee";

const CheckTaskListPage = () => {
  const { employeeCred } = useEmployee();
  const { taskList, loading } = useGetListForEmployee(employeeCred);

  return (
    <>
      {loading ? (
        <div className="p-8">Cargando...</div>
      ) : (
        <div className="flex flex-col gap-4 p-8">
          <Heading>
            Selecciona una lista de tareas para {employeeCred.puesto}:
          </Heading>

          <div className="flex h-[50%] flex-col gap-4 overflow-y-scroll p-2">
            {taskList ? (
              // eslint-disable-next-line array-callback-return
              taskList.map((taskListItem) => {
                const doneTasks = taskListItem.tareas.filter(
                  (task) => task.done === true,
                );

                if (taskListItem.frecuencia !== "Eventual")
                  return (
                    <NavLink
                      key={taskListItem.id}
                      to={"/hola/marcartareas"}
                      state={{ list: taskListItem, user: employeeCred }}
                    >
                      <div className="flex w-[100%] cursor-pointer select-none justify-between rounded bg-red-200 p-4 text-sm shadow-sm transition-all hover:translate-y-1 hover:bg-red-100 md:text-lg">
                        <p>{taskListItem.nombreDeLista}</p>
                        <p className="invisible md:visible">
                          Tareas completadas: {doneTasks.length} de{" "}
                          {taskListItem.tareas.length}
                        </p>
                      </div>
                    </NavLink>
                  );
              })
            ) : (
              <Subtext>No hay listas de tareas aún</Subtext>
            )}
          </div>

          <br />
          <br />
          <Heading>Listas eventuales para {employeeCred.puesto}:</Heading>

          <div className="flex h-[50%] flex-col gap-4 overflow-y-scroll p-2">
            {taskList ? (
              // eslint-disable-next-line array-callback-return
              taskList.map((taskListItem) => {
                if (taskListItem.frecuencia === "Eventual")
                  return (
                    <NavLink
                      key={taskListItem.id}
                      to={"/hola/marcartareas"}
                      state={{ list: taskListItem, user: employeeCred }}
                    >
                      <div className="flex w-[100%] cursor-pointer select-none justify-between rounded bg-yellow-200 p-4 text-sm shadow-sm transition-all hover:translate-y-1 hover:bg-yellow-100 md:text-lg">
                        <p>{taskListItem.nombreDeLista}</p>
                        <p className="invisible md:visible">
                          Numero de tareas: {taskListItem.tareas.length}
                        </p>
                      </div>
                    </NavLink>
                  );
              })
            ) : (
              <Subtext>No hay listas eventuales aún</Subtext>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CheckTaskListPage;
