import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import CollapseObservation from "./CollapseObservation";
import AddObservationForm from "./AddObservationForm";

const CheckEventualTask = ({
  handleClose,
  selectedTask,
  listID,
  userCredentials,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [addObs, setAddObs] = useState(false);
  const user = `${userCredentials.nombre} ${userCredentials.apellido}`;

  const observation = {
    user: selectedTask.observation?.user || "Ninguno",
    message: selectedTask.observation?.message || "Sin mensaje",
  };

  return (
    <>
      {success ? (
        <div className="flex flex-col items-center gap-4 p-2">
          Cambios guardados
          <FaEye color="green" size={50} />
          <button
            onClick={handleClose}
            type="button"
            className="btn btn-success w-[50%]"
          >
            Ok
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-4 p-2">
          <FaEye color="yellow" size={50} />
          <p className="text-lg">Detalles de la tarea</p>
          <div className="flex flex-col gap-2 md:w-[500px]">
            <div className="mb-4 grid grid-cols-2 gap-2">
              <p>Tarea: {selectedTask.name}</p>
              <p>Descripción: {selectedTask.desc}</p>
              <p>Nota: {selectedTask.note}</p>
            </div>
            {addObs ? (
              <AddObservationForm
                listID={listID}
                setSubmitting={setIsSubmitting}
                submitting={isSubmitting}
                setSuccess={setSuccess}
                taskID={selectedTask.id}
                user={user}
              />
            ) : (
              <CollapseObservation observation={observation} />
            )}
          </div>

          <div className="flex justify-center gap-8">
            <button
              onClick={() => setAddObs(!addObs)}
              type="button"
              className="btn btn-warning"
            >
              {addObs ? "Cancelar" : "Añadir Observación"}
            </button>

            <button
              onClick={handleClose}
              type="button"
              className="| btn btn-success"
            >
              Cerrar ventana
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckEventualTask;
