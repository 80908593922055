import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import TaskItemButton from "../../elements/TaskItemButton";
import TaskModal from "../../elements/TaskModal";
import useGetTasks from "../../hooks/useGetTasks";
import BackNavbar from "../BackNavbar";
import CheckTaskAsDoneForm from "../CheckTaskAsDoneForm";
import ShowCancelTask from "../ShowCancelTask";
import CheckEventualTask from "../CheckEventualTask";

const MarkTaskPage = () => {
  const location = useLocation();

  const data = location.state;
  const list = data.list;
  const listPeriodicy = list.frecuencia;
  const user = data.user;
  const id = list.id;
  const parentList = list.nombreDeLista;

  const { tasks, loading, tasksCount, tasksDoneCount } = useGetTasks(id);

  const [open, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskIsPending, setTaskisPending] = useState(false);

  const handleOpenModal = (task) => {
    setSelectedTask(task);
    if (!task.done) {
      setTaskisPending(true);
    } else {
      setTaskisPending(false);
    }
    setIsOpen(true);
  };

  return (
    <div className="h-[calc(100vh-100px)]">
      <BackNavbar
        periodicy={listPeriodicy}
        loading={loading}
        tasksCount={tasksCount}
        tasksDoneCount={tasksDoneCount}
      />

      {tasks.length > 0 && !loading ? (
        <div className="flex h-[90%] flex-col gap-4 overflow-y-auto p-4">
          {tasks.map((task, index) => {
            return (
              <TaskItemButton
                key={index}
                task={task}
                openModal={() => handleOpenModal(task)}
              />
            );
          })}
        </div>
      ) : (
        <p className="ml-8">Cargando...</p>
      )}

      <TaskModal
        task={selectedTask}
        open={open}
        onClose={() => {
          setIsOpen(false);
          setSelectedTask(null);
        }}
      >
        {selectedTask && taskIsPending ? (
          listPeriodicy !== "Eventual" ? (
            <CheckTaskAsDoneForm
              parentList={parentList}
              listID={id}
              user={user}
              selectedTask={selectedTask}
              setIsOpen={setIsOpen}
              setSelectedTask={setSelectedTask}
            />
          ) : (
            <CheckEventualTask
              selectedTask={selectedTask}
              listID={id}
              userCredentials={user}
              handleClose={() => {
                setSelectedTask(null);
                setIsOpen(false);
              }}
            />
          )
        ) : (
          <ShowCancelTask
            selectedTask={selectedTask}
            listID={id}
            handleClose={() => {
              setSelectedTask(null);
              setIsOpen(false);
            }}
          />
        )}
      </TaskModal>
    </div>
  );
};

export default MarkTaskPage;
