import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";

const AddObservationForm = ({
  listID,
  taskID,
  user,
  setSubmitting,
  setSuccess,
  submitting,
}) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const errorMessage = "Este campo no puede estar vacío";

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, "listas", listID, "tareas", taskID);
    setSubmitting(true);
    if (message === "") {
      setError(true);
      setSubmitting(false);
    } else {
      updateDoc(docRef, {
        observation: {
          user: user,
          message: message,
        },
      });
      setSubmitting(false);
      setSuccess(true);
    }
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col gap-2">
      <p>Nueva observación: </p>
      {error && <p className="text-red-500">{errorMessage}</p>}
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="textarea textarea-secondary w-full"
        name="observation"
        id="observation"
        placeholder="Agregar observación"
      ></textarea>
      <button disabled={submitting} className="btn btn-secondary">
        Guardar
      </button>
    </form>
  );
};

export default AddObservationForm;
