import React, { useState } from "react";
import { FaTasks } from "react-icons/fa";
import Heading from "../elements/Heading";
import Subtext from "../elements/Subtext";
import { db } from "../firebase/FirebaseConfig";
import {
  updateDoc,
  doc,
  Timestamp,
  addDoc,
  collection,
} from "firebase/firestore";
import { MdOutlineDownloadDone } from "react-icons/md";

const CheckTaskAsDoneForm = ({
  selectedTask,
  setIsOpen,
  user,
  listID,
  setSelectedTask,
  parentList,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setSuccess(false);
    setSelectedTask(null);
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const docRef = doc(db, "listas", listID, "tareas", selectedTask.id);
      const colRef = collection(db, "historial");
      await updateDoc(docRef, {
        done: true,
        doneBy: user,
        timeOfCompletion: Timestamp.now(),
      });
      await addDoc(colRef, {
        list: parentList,
        name: selectedTask.name,
        desc: selectedTask.desc,
        note: selectedTask.note,
        doneBy: user,
        timeOfCompletion: Timestamp.now(),
      });
      setSuccess(true);
      setIsSubmitting(false);
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div className="flex flex-col gap-4 p-4">
      {isSubmitting ? (
        "Guardando..."
      ) : (
        <>
          {success ? (
            <div className="flex flex-col items-center gap-4 p-2">
              Cambios guardados
              <MdOutlineDownloadDone color="green" size={50} />
              <button
                onClick={handleClose}
                type="button"
                className="btn btn-success w-[50%]"
              >
                Ok
              </button>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex justify-center">
                <FaTasks color="yellow" size={50} />
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <Heading>Tarea: {selectedTask.name}</Heading>
              <p className="text-sm">
                Descripción:{" "}
                {selectedTask.desc ? selectedTask.desc : "Sin descripción"}
              </p>
              <p className="text-sm">
                Nota: {selectedTask.note ? selectedTask.note : "Sin Notas"}
              </p>
              <Subtext>
                Al marcar como tarea completada, el responsable de la tarea será{" "}
                {user.nombre}
              </Subtext>
              <div className="flex justify-center gap-8">
                <button
                  disabled={isSubmitting ? true : false}
                  type="button"
                  onClick={handleClose}
                  className="btn btn-neutral"
                >
                  Cancelar
                </button>
                <button
                  disabled={isSubmitting ? true : false}
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                  className="btn btn-success"
                >
                  Marcar como completada
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CheckTaskAsDoneForm;
