import React from "react";

const TaskItemButton = ({ task, openModal }) => {
  return (
    <div
      onClick={openModal}
      className={
        task.done
          ? "btn btn-outline btn-success"
          : "btn btn-outline btn-warning "
      }
    >
      <p>{task.name}</p>
    </div>
  );
};

export default TaskItemButton;
