import React from "react";

const CollapseObservation = ({ observation }) => {
  return (
    <div className="collapse border border-base-300 bg-base-100">
      <input type="checkbox" />
      <div className="collapse-title font-semibold">
        Click para revisar observaciónes
      </div>
      <div className="collapse-content text-sm">
        <div className="overflow-x-auto rounded-box border border-base-content/5 bg-base-100">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Mensaje</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              <tr>
                <td>{observation.user}</td>
                <td>{observation.message}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CollapseObservation;
