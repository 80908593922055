import React, { useState } from "react";
import { MdOutlineDownloadDone } from "react-icons/md";
import useFormatTimestampDateShort from "../hooks/useFormatTimestampDateShort";
import CancelDoneTaskForm from "./CancelDoneTaskForm";

const ShowCancelTask = ({ handleClose, selectedTask, listID }) => {
  const firebaseTimestamp = selectedTask.timeOfCompletion;
  const { date } = useFormatTimestampDateShort(firebaseTimestamp);
  const user = selectedTask.doneBy;
  const [askCancel, SetAskCancel] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <>
      {success ? (
        <div className="flex flex-col items-center gap-4 p-2">
          Cambios guardados
          <MdOutlineDownloadDone color="green" size={50} />
          <button
            onClick={handleClose}
            type="button"
            className="btn btn-success w-[50%]"
          >
            Ok
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-4 p-2">
          <MdOutlineDownloadDone color="green" size={50} />
          Tarea completada
          <div className="flex flex-col gap-2 md:w-[500px]">
            <p>Tarea: {selectedTask.name}</p>
            <p>Descripción: {selectedTask.desc}</p>
            <p>Nota: {selectedTask.note}</p>
            <p>
              Completado por: {user.nombre} {user.apellido}
            </p>
            <p>Fecha: {date} </p>
          </div>
          {askCancel && (
            <div className="mb-4 mt-4">
              <CancelDoneTaskForm
                cancelRequest={() => SetAskCancel(false)}
                listID={listID}
                docID={selectedTask.id}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                setSuccess={setSuccess}
              />
            </div>
          )}
          {!askCancel && (
            <div className="flex justify-center gap-8">
              <button
                onClick={() => SetAskCancel(true)}
                type="button"
                className="btn btn-error"
              >
                Cancelar tarea
              </button>

              <button
                onClick={handleClose}
                type="button"
                className="| btn btn-success"
              >
                Cerrar ventana
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShowCancelTask;
